/*------------------------------------------------------------*\
    Footer
\*------------------------------------------------------------*/

footer {
    padding: 80px 0;

    .footer-block {
        margin-bottom: 30px;

        .block-title {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: 600;
        }
        .block-content {
            ul {
                list-style: none;
                padding: 0;
            }
            a {
                color: $gray-darker;
            }
            img {
                max-width: 80px;

                + img {
                    margin-left: 10px;
                }
            }
            .contact {
                li {
                    + li {
                        margin-top: 4px;
                    }
                }
                .small {
                    color: $gray-light;
                }
            }
        }
    }
    hr {
        margin: 40px 0;
        border-color: lighten($gray-base, 90.5);
    }
}
.footer-bottom {
    border-top: 1px solid $gray-lighter;
    padding: 15px 0;
    font-size: 12px;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: inline-block;

            + li {
                &:before {
                    content:'|';
                    margin-left: 4px;
                    margin-right: 6px;
                    color: darken($gray-lighter, 10);
                }
            }
        }
    }
    .copyright {
        display: inline-block;
    }
    .bottom-nav {
        float: right;

        a {
            + a {
                &:before {
                    content: '| ';
                }
            }
        }
        @media (max-width: $screen-sm) {
            float: none;;
        }
    }
}
