/*------------------------------------------------------------*\
    Forms
\*------------------------------------------------------------*/

.form-group {
    em {
        color: $brand-danger;
    }
}
.form-control {
    height: 36px;
    box-shadow: none;
    -webkit-box-shadow: none;
    //border: none;


    &:hover, &:focus {
        box-shadow: none;
        -webkit-box-shadow: none;
        border-color: $gray-light;
        @include box-shadow(0px 2px 5px 0px rgba(0,0,0,0.15));
    }
}
