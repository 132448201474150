/*------------------------------------------------------------*\
    Breadcrumbs
\*------------------------------------------------------------*/

.breadcrumbs-wrapper {
    margin-top: 15px;

    .breadcrumb {
        padding: 0;
        margin-bottom: 0;
        background: transparent;

        @media (max-width: $screen-sm) {
            font-size: 12px;
        }
    }
}
