/*------------------------------------------------------------*\
    Login
\*------------------------------------------------------------*/

.login {
    padding: 60px 0;

    .logo {
        padding: 10px 0;
        text-align: center;

        img {
            max-width: 300px;
        }
    }
    .panel {
        .panel-title {
            display: inline-block;
        }
        .countries {
            display: inline-block;
            float: right;
        }
        .actions {
            margin-bottom: 0;
            .btn {
                margin-right: 10px;
            }
        }
    }
}
