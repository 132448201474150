/*! formstone v1.3.3 [background.css] 2017-04-18 | GPL-3.0 License | formstone.it */

.fs-background {
    overflow: hidden;
    position: relative
}
.fs-background,
.fs-background-container,
.fs-background-media {
    -webkit-transition: none;
    transition: none
}
.fs-background-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden
}
.fs-background-media {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0
}
.fs-background-media.fs-background-animated {
    -webkit-transition: opacity .5s linear;
    transition: opacity .5s linear
}
.fs-background-media iframe,
.fs-background-media img,
.fs-background-media video {
    width: 100%;
    height: 100%;
    display: block;
    -webkit-user-drag: none
}
.fs-background-media.fs-background-fixed,
.fs-background-media.fs-background-native {
    width: 100%;
    height: 100%
}
.fs-background-media.fs-background-fixed img,
.fs-background-media.fs-background-native img {
    display: none
}
.fs-background-media.fs-background-native {
    background-position: center;
    background-size: cover
}
.fs-background-media.fs-background-fixed {
    background-position: center;
    background-attachment: fixed
}
.fs-background-embed.fs-background-ready:after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ''
}
.fs-background-embed.fs-background-ready iframe {
    z-index: 0
}