/*------------------------------------------------------------*\
    MIXINS
\*------------------------------------------------------------*/

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    border-radius: $radius;
    background-clip: padding-box;
}

@mixin border-top-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}
@mixin border-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
    background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    background-clip: padding-box;
}
@mixin border-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
    background-clip: padding-box;
}

@mixin box-shadow($arguments) {
  box-shadow: $arguments;
  -moz-box-shadow: $arguments;
  -webkit-box-shadow: $arguments;
}

@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin transition($transition-property, $transition-time, $method) {
    -webkit-transition: $transition-property $transition-time $method;
    -moz-transition: $transition-property $transition-time $method;
    -ms-transition: $transition-property $transition-time $method;
    -o-transition: $transition-property $transition-time $method;
    transition: $transition-property $transition-time $method;
}

@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: $screen-xs-min) { @content; }
  }
 
  @else if $class == sm {
    @media (max-width: $screen-sm-min) { @content; }
  }
 
  @else if $class == md {
    @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) { @content; }
  }
 
  @else if $class == lg {
    @media (min-width: $screen-md-min) and (max-width: $screen-lg-max) { @content; }
  }
 
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}

@mixin animation($arguments) {
  -webkit-animation: $arguments;
  -moz-animation: $arguments;
  animation: $arguments;
}
  
@mixin panel-right($color) {
    $path1: "<path class='body' fill='#fff' d='M6.9,0c-10.4,89.7-10,206.3,29.9,332.1c7.2,22.7,15.2,44.2,23.8,64.4c17.4,33.8,47.2,84.6,94.6,137.7
	c77.9,87.1,97.4,82.9,141.8,137.7c27.3,33.8,62.3,87.6,85.5,169.9c70.3,0,140.6,0,210.9,0C593,561.3,592.5,280.6,592.1,0H6.9z'/>";
		$path2: "<path class='header' fill='#{$color}' d='M5.6,0h586.5c0.1,55.4,0.1,110.8,0.2,166.2c-21.7-4.4-52-10.3-88.5-16.2c-70.4-11.5-111.1-18.2-154-20
	c-116.6-5-143.2,31-253,21c-41-3.8-74-12.1-95-18.4C0.7,117.7,0,101.9,0,85.2C-0.1,54,2.2,25.4,5.6,0z'/>";
	
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 592.4 841.9'>#{$path1,$path2}</svg>");
    background-size: cover;
    background-repeat: no-repeat;
}

@mixin panel-left($color) {
    $path1: "<path class='body' fill='#fff' d='M0,0c0.5,140.3,0.8,280.6,0.8,420.9c0,140.3-0.3,280.6-0.8,421c70.3,0,140.7,0,211,0
	c7.5-27.9,19.9-64,41.3-103.2c8.7-15.9,22.2-39.5,44.3-66.8c44.4-54.8,63.9-50.6,141.8-137.7c47.4-53.1,77.2-103.9,94.6-137.7
	c8.6-20.2,16.6-41.7,23.8-64.4C596.7,206.3,597.1,89.7,586.7,0C391.1,0,195.6,0,0,0z'/>";
	$path2: "<path class='header' fill='#{$color}' d='M591.7,63.7c1,24.5,0.9,47.6,0,68.9c-21,6.3-53.9,14.6-95,18.4c-109.8,10-136.4-26-253-21
	c-41.9,1.8-80.9,8.1-154,20c-37,6-67.8,11.9-89.7,16.4C0,110.9,0,55.5,0,0c195.6,0,391.1,0,586.7,0C589,19.8,590.8,41.1,591.7,63.7z'/>";
  
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 592.4 841.9'>#{$path1,$path2}</svg>");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: -70px;
}

@mixin wave-up($color) {
    $path: "<path class='body' fill='#{$color}' d='M1920,133.7H0C0,110.9,0,88,0,65.2c0-0.2,0,0.3,0,0.5c69.3-11.5,160.1-24.2,267-33c107.4-8.8,215.8-17.7,354-10
	c190.7,10.6,239.7,41.9,401,27c63.1-5.8,51.8-3.2,156-24c151.1-30.2,274.4-26.7,371-24c92.9,2.6,220.5,13.7,371,50
	C1920,79,1920,106.4,1920,133.7z'/>";

    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 133.7'>#{$path}</svg>");
    background-size: cover;
    background-repeat: no-repeat;
}

@mixin wave-down($color) {
    $path: "<path class='body' fill='#{$color}' d='M0,0h1920c0,22.8,0,45.7,0,68.5c0,0.2,0-0.3,0-0.5c-69.3,11.5-160.1,24.2-267,33c-107.4,8.8-215.8,17.7-354,10
    c-190.7-10.6-239.7-41.9-401-27c-63.1,5.8-51.8,3.2-156,24c-151.1,30.2-274.4,26.7-371,24c-92.9-2.6-220.5-13.7-371-50
    C0,54.7,0,27.3,0,0z'/>";

    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 133.7'>#{$path}</svg>");
    background-size: cover;
    background-repeat: no-repeat;
}
