/*------------------------------------------------------------*\
    ANIMATIONS
\*------------------------------------------------------------*/
@include keyframes(slide-in-left) {
    100% {
        transform: translateX(0%);
    }
}

@include keyframes(slide-out-left) {
    100% {
        transform: translateX(100%);
    }
}

@include keyframes(slide-in-right) {
    100% {
        transform: translateX(0%);
    }
}

@include keyframes(slide-out-left) {
    100% {
        transform: translateX(-100%);
    }
}

@include keyframes(slide-in-top) {
    100% {
        transform: translateY(0%);
    }
}

@include keyframes(slide-out-top) {
    100% {
        transform: translateY(-100%);
    }
}

@include keyframes(slide-in-bottom) {
    100% {
        transform: translateY(0%);
    }
}

@include keyframes(slide-out-bottom) {
    100% {
        transform: translateY(100%);
    }
}

@include keyframes(opacity-in) {
    100% {
        opacity: 1;
    }
}

@include keyframes(bounce) {
    100% {
        margin-top: 0;
    }
    0%, 30%, 50%, 70%, 100% {
        margin-top: 0;
    }
    40% {
        margin-top: -10px;
    }
    60% {
        margin-top: -5px;
    }
}
@include keyframes(zoominout) {
    0% {
        background-size: 100%;
    }
    50% {
        background-size: 130%;
    }
    100% {
        background-size: 100%;
    }
}
