.offcanvas-nonscroll {
	height: 100%;
	overflow: hidden;
}
.navbar-curtain {
	display: none;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1000;
	background: rgba(0,0,0,0.4);

	&.collapse {
		display: none;
	}
	&.in {
		display: block;
	}
}
@media (max-width: $grid-float-breakpoint-max) {
	.navbar-offcanvas {
		&.navbar-full {
			.navbar-nav {
				width: 100%;

				@media (min-width: $screen-sm) and (max-width: $screen-md) {
					max-width: 360px;
				}
			}
		}
		.navbar-nav {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			margin: 0;
			padding-top: 1em;
			padding-left: 0em;
			padding-right: 0em;
			padding-bottom: 1em;
			width: 250px;
			z-index: 1110;
			background: #fff;
			overflow-y: scroll;
			transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
			-webkit-overflow-scrolling: auto;

			> li {
				> a {
					padding: 10px 15px;
					color: #333;
				}
			}
		}
		.in {
			.navbar-nav {
				display: block;
				@include animation(slide-in-left 0.2s forwards);
			}
			.navbar-curtain {
				display: block;
			}
		}
		.wait {
			display: block;
			.navbar-nav {
				transform: translateX(0);
            	-webkit-transform: translateX(0);
			}
			.navbar-curtain {
				opacity: 0;
				transition: opacity 0.2s ease-in-out;
			}
		}
		.out {
			.navbar-nav {
            	@include animation(slide-out-left 0.2s forwards);
			}
		}
	}
	.navbar-right {
		display: none;
	}
	.navbar-default {
		.menu-bar {
			display: block;
			margin-top: -1em;
			padding: 0.6em 1em;
			background: $brand-primary;
			color: #fff;

			.title {
				font-size: 1.2em;
			}
			.close-nav {
				position: absolute;
				font-size: 1.2em;
				color: #fff;
				right: 1em;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
			}
		}
		
		.navbar-nav {
			> .menu-bottom {
				display: block;

				a {
					display: inline-block;
				}
				img {
					max-width: 24px;
				}
			}
		}
	}
}
