/*------------------------------------------------------------*\
    Header
\*------------------------------------------------------------*/

header {
	background: #fff;
	@media (max-width: $grid-float-breakpoint-max) {
		background: $brand-primary;
	}

	.header-top {
		@media (min-width: $screen-md) {
			padding: 10px 0;
		}

		.row {
			@media (min-width: $screen-md) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}
	.logo {
		display: flex;
		align-items: center;

		a {
			text-decoration: none;
		}
		img {
			max-width: 100px;
		}
		.logo-title {
			font-size: $font-size-h1;
			font-family: $headings-font-family;
			font-weight: 600;
		}
		@media (min-width: $screen-sm) and (max-width: $screen-md) {
			min-width: 175px;
				
			img {
				max-width: 60px;
			}
			.logo-title {
				font-size: $font-size-h4;
			}
		}
	}
	@media (max-width: $grid-float-breakpoint-max) {
		.header-top {
			padding: 0;
		}
		.logo {
			display: none;
		}
		.contact {
			display: none;
		}
	}
}
