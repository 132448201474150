/*------------------------------------------------------------*\
    Home
\*------------------------------------------------------------*/

.hero {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 420px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50%;

	.heading-title {
		margin-top: 0;
		color: #fff;
		font-size: 42px;

		@media (max-width: $screen-sm) {
			text-align: center;
		}
	}
	.hero-content {
		max-width: 600px;
		padding: 15px;
		background: lighten($brand-primary, 25);
		@include border-radius(12px);

		@media (max-width: $screen-sm) {
			
		}
	}
	@media (max-width: $screen-sm) {
		min-height: 180px;
	}
	&.page-hero {
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

.blocks {
	background: $gray-lighter;

	.block {
		position: relative;
		display: block;
		margin-bottom: 15px;
		background: #fff;
		text-decoration: none;
		@include box-shadow(0px 0px 6px 0px rgba(0,0,0,0.05));
		@include transition(all, 300ms, ease-in-out);
		
		&:hover, &:focus {
			@include box-shadow(0px 0px 8px 0px rgba(0,0,0,0.15));
			@include transition(all, 300ms, ease-in-out);
		}
		.block-content {
			padding: 15px;
			padding-top: 0;
		}
	}
}

.two-blocks {
	display: flex;
	align-items: center;

	.content {
		padding: 40px;
	}
	.block-left, .block-right {
		flex: 50%;
	}
	@media (max-width: $screen-sm) {
		flex-direction: column;

		.block-left, .block-right {
			flex: 100%;
			width: 100%;
			flex: 2;
			order: 2;
		}
		.image {
			flex: 1;
			order: 1;
		}
	}
}
