/*------------------------------------------------------------*\
    Page
\*------------------------------------------------------------*/

.page-title {
	margin-top: 60px;
	position: relative;
	padding: 120px 0 0px;

	.container {
		position: relative;
		z-index: 10;
	}
	svg {
		position: absolute;
		bottom: -34px;
		z-index: 1;
	}
	@media (max-width: $screen-sm) {
		margin-top: 30px;
		padding: 40px 0 0px;

		svg {
			bottom: -10px;
		}
	}
}
.content {
	background: #fff;
	padding: 80px 0px;

	h1 {
		margin-top: 0;
	}
	.sideblock {
		margin-top: 60px;
	}
	.contact {
		padding: 15px;
		background: $gray-lighter;
	}
	&.page {
		padding: 40px 0px;
	}
	.artist-left {
		margin-bottom: 15px;
		padding: 15px;
		background: $gray-lighter;
		@include border-bottom-radius(5px);

		.title {
			margin-top: 0;
		}
		dl {
			.item {
				display: flex;

				+ .item {
					margin-top: 8px;
				}
				dt {
					width: 100px;
				}
			}
		}
		.btn {
			width: 100%;
		}
	}
	&.bg-gray-lighter {
		background: $gray-lighter;
	}
}
.sidebar {
	.menu-categories {
		padding: 0;
		list-style: none;

		> li {
			> a {
				color: $brand-primary;
			}
			+ li {
				margin-top: 4px;
			}
		}
	}
	.filters {
		margin-bottom: 15px;

		.price-range {
			margin-top: 15px;
			margin-left: 10px;
			margin-right: 10px;
		}
		.ui-slider-handle {
			cursor: pointer;
		}
	}
}

.banner {
	overflow: hidden;

	.svg-top, .svg-bottom {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 100;
	}
	.svg-top {
		top: 0;
	}
	.svg-bottom {
		bottom: 0;
	}
}

.action-bar {
	position: relative;
	padding: 80px 0;
	background: #fff;
	text-align: center;
	overflow: hidden;

	.container {
		position: relative;
		z-index: 1100;
	}
	&.is-primary, &.is-secondary, &.is-third {
		color: #fff;
	}
	&.is-primary {
		background: $brand-primary;
	}
	&.is-secondary {
		background: $brand-secondary;
	}
	&.is-third {
		background: $brand-cta;
	}
	.title {
		margin-bottom: 30px;
		text-transform: uppercase;
		font-size: $font-size-h1;
		font-weight: 700;
	}
	@media (max-width: $screen-sm) {
		.title {
			font-size: 26px;
		}
	}
	.svg-top, .svg-bottom {
		position: absolute;
		left: 0;
		right: 0;
		z-index: 100;
	}
	.svg-top {
		top: 0;
	}
	.svg-bottom {
		bottom: 0;
	}
}
